const hasAllDocuments = (companyDocuments, documents) => documents.every((documentType) => {
  const requiredYears = documentType.years;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  if (requiredYears === null) {
    // Check if there's at least one document with a null year
    return companyDocuments.some((document) => document.documentType
       === documentType.code && !documentType.documentDate);
  }
  // Create an array of required years
  const requiredYearsArray = Array.from(
    { length: requiredYears },
    (_, index) => currentYear - index,
  );

  // Check if there's at least one document for each required year
  return requiredYearsArray.every((year) => companyDocuments
    .some((document) => document.documentType === documentType.code
          && document.documentDate
          && parseInt(document.documentDate.substring(0, 4), 10) === year));
});

export default hasAllDocuments;
