/* eslint-disable import/prefer-default-export */
export const openUrlAndDownload = (url, filename) => {
  if (url === null) return;
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    filename,
  );
  link.setAttribute(
    'target',
    '_blank',
  );
  // Append to html link element page
  document.body.appendChild(link);
  // Start download
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
};
