/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import React from 'react';
import { DOCUMENT_MAPPER } from '../constants/documents';
import DocumentChip from '../components/cells/DocumentsChip';

export const buildCompanyDocumentsArray = (companyDocuments, countryDocumentTypes) => countryDocumentTypes.map((countryDocument) => {
  const companyDocument = companyDocuments.find(
    ({ documentType }) => documentType === countryDocument.code,
  );
  const matchingDocMapper = DOCUMENT_MAPPER?.find((mapper) => mapper.documentType === countryDocument.code);
  if (!matchingDocMapper) {
    return {
      documentType: countryDocument.code,
      icon: <InsertDriveFile />,
      label: countryDocument.shortName,
      lastDate: companyDocument?.lastDate,
      url: companyDocument?.lastFile,
      component: DocumentChip,
      companyDocument,
    };
  }
  return {
    documentType: matchingDocMapper.documentType,
    icon: matchingDocMapper.icon,
    label: matchingDocMapper.label,
    lastDate: companyDocument?.lastDate,
    url: companyDocument?.lastFile,
    mutation: matchingDocMapper.mutation,
    component: matchingDocMapper.Component,
    companyDocument,
  };
});
