export const isRouteDisabled = (user) => !user.selectedCompany && user.demo;

export const hasShowSubRoutes = (user, subRoutes) => {
  if (user.isSuperuser) {
    return true;
  }
  return subRoutes.filter((route) => route.show).length > 0;
};

export const userHasRoutePermission = (user, ...perms) => {
  if (user.isSuperuser) {
    return true;
  }
  return [...perms].some((perm) => user.permissions.includes(perm));
};
