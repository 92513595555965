import React from 'react';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const disabledConditionsChile = (invoice) => !(invoice.company.hasInvoiceProviderCredentials
   || invoice.receiver?.company?.hasInvoiceProviderCredentials);

const disabledConditionsMexico = (invoice) => !invoice.company.hasSiiCredentials;

const disabledConditions = {
  Chile: disabledConditionsChile,
  Mexico: disabledConditionsMexico,
};

const disabledDocumentsDownload = (
  invoice,
  countryName,
) => disabledConditions[countryName](invoice);

const chileDisabledDocumentsDownloadMessage = (
  <>
    <Typography variant="body2" component="span">
      El pdf/xml de esta factura no está disponible ya que no tenemos
      disponibles tus credenciales de facturador.
      Te invitamos a que pinches
    </Typography>
    <Link
      component={MuiLink}
      underline="hover"
      to={{ pathname: '/app/profile' }}
      color="primary"
      target="_blank"
      rel="noopener noreferrer"
      variant="body2"
      sx={{
        '&:hover': {
          color: (theme) => `${theme.palette.primary.main} !important`,
        },
      }}
    >
      {' '}
      aquí{' '}
    </Link>
    <Typography variant="body2" component="span">
      para que sincronices con nosotros tus credenciales y puedas vivir la experiencia completa de
      Fingo.
    </Typography>
  </>
);

const disabledMessages = {
  Chile: chileDisabledDocumentsDownloadMessage,
  Mexico: 'Debes sincronizar tus credenciales con Fingo para poder descargar este documento',
};

const disabledDownloadDocumentsMessage = (countryName) => disabledMessages[countryName];

const errorDownloadDocumentsMessages = {
  Chile: 'No pudimos cargar el documento de tu factura. Asegúrate que las credenciales de tu facturador estén correctas en tu perfil, e intente nuevamente',
  Mexico: 'Hubo un error al descargar el documento',
};

const errorDownloadDocumentsMessage = (countryName) => errorDownloadDocumentsMessages[countryName];

export {
  disabledDocumentsDownload,
  disabledDownloadDocumentsMessage,
  errorDownloadDocumentsMessage,
};
