/* Here we can declare new validation types */
/* eslint-disable import/prefer-default-export */
import { checkRut } from 'react-rut-formatter';
import { validEmail, validPhone, validateRfc } from './forms';

export const validationTypes = (value) => ({
  email: { message: 'Ingresa un email válido', truthFunction: () => validEmail(value) },
  phone: { message: 'Número incorrecto', truthFunction: () => validPhone(String(value)) },
  text: { message: '', truthFunction: () => true },
  rut: { message: 'Ingrese un rut correcto', truthFunction: () => checkRut(value) },
  rfc: { message: 'Ingrese un RFC correcto', truthFunction: () => validateRfc(value) },
});
