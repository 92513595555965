/* eslint-disable import/prefer-default-export */
export const actionTypeToSpanish = {
  PHONE_CALL_TO_RECEIVER: 'Consulta telefónica al receptor',
  PHONE_CALL_TO_ISSUER: 'Consulta telefónica al emisor',
  MAIL_TO_RECEIVER: 'Consulta vía correo al receptor',
  MAIL_TO_ISSUER: 'Consulta vía correo al emisor',
  RECEIVER_FORM_MAIL: 'Consulta vía correo sobre fecha de pago al receptor',
  ISSUER_FORM_MAIL: 'Consulta vía correo sobre fecha de pago al emisor',
  AUTOMATIC_MAIL_TO_RECEIVER: 'Consulta vía correo al receptor',
  AUTOMATIC_MAIL_TO_ISSUER: 'Consulta vía correo al emisor',
  OTHER: 'Otra gestión',
  INSIST_RATIFICATION: 'Insistencia en ratificación',
  INITIATE_RATIFICATION_PROCESS: 'Inicio de proceso de ratificación',
};
