import { shoppingCartDialogVar } from '../apollo/reactive-variables/shopping-cart';
import { PREOFFERS, OFFERS } from '../constants';

export const setOpenShoppingCart = (isOpen, type) => {
  shoppingCartDialogVar({ isOpen, type });
};

export const closeShoppingCart = () => {
  setOpenShoppingCart(false, null);
};

export const openInvoiceShoppingCart = () => {
  setOpenShoppingCart(true, PREOFFERS);
};

export const openOfferShoppingCart = () => {
  setOpenShoppingCart(true, OFFERS);
};

export const openShoppingCart = (type) => {
  const methods = {};
  methods[PREOFFERS] = openInvoiceShoppingCart;
  methods[OFFERS] = openOfferShoppingCart;
  return methods[type]();
};

export const toggleCart = (type) => {
  const { isOpen } = shoppingCartDialogVar();
  if (isOpen) {
    closeShoppingCart(type);
  } else {
    openShoppingCart(type);
  }
};
