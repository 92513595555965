/* eslint-disable react/no-danger */
import React from 'react';

export const formatText = (text) => {
  const final = text
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    .replace(/__(.*?)__/g, '<u>$1</u>')
    .replace(/~~(.*?)~~/g, '<s>$1</s>')
    .replace(/jline/g, '</br>');

  return <div dangerouslySetInnerHTML={{ __html: final }} />;
};

export const capitalizeFirstLetter = (s) => s[0].toUpperCase() + s.slice(1).toLowerCase();

export const createQueryString = (params) => Object.entries(params)
  .filter(([, value]) => Boolean(value))
  .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
  .join('&');
