/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import Cookies from 'universal-cookie';
import AwaitLock from 'await-lock';
import { logIn, logOut } from '../apollo/reactive-variables/localUpdates';

const lock = new AwaitLock();

const rawRefresh = `mutation refreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    token
    refreshToken
    payload
    __typename
  }
}`;

export const getValidToken = async (uri) => {
  await lock.acquireAsync();
  const vars = await JSON.parse(localStorage.getItem('apolloVariables'));
  try {
    const expires = vars?.tokenExpire || 0;
    const loggedIn = vars?.refreshToken;
    if (loggedIn && expires < moment().unix()) {
      const cookies = new Cookies();
      const utmCampaign = cookies.get('utm_campaign') || null;
      const body = {
        operationName: 'refreshToken',
        variables: {
          refreshToken: vars.refreshToken,
          utmCampaign,
        },
        query: rawRefresh,
      };
      const refreshResponse = await fetch(uri, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const refreshJSON = await refreshResponse.json();
      if (refreshJSON.errors && refreshJSON.errors[0].message === 'Invalid refresh token') {
        logOut();
        window.location = '/';
      }
      const user = refreshJSON.data.refreshToken;
      logIn(user);
      return user.token;
    }
  } finally {
    lock.release();
  }
  return vars?.token;
};
