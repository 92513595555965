const OPERATOR_MAP = {
  contains: '_Icontains',
  equals: '_Iexact',
  startsWith: '_Istartswith',
  endsWith: '_IendsWith',
  is: '_Iexact',
  before: '_Lt',
  after: '_Gt',
  onOrBefore: '_Lte',
  onOrAfter: '_Gte',
  isEmpty: '_Isnull',
  isAnyOf: '_In',
  '>': '_Gt',
  '>=': '_Gte',
  '<': '_Lt',
  '<=': '_Lte',
  '=': '_Iexact',
};

export const buildOrderByString = (sortModel) => {
  if (!sortModel.length) {
    return '';
  }
  return sortModel[0].sort === 'desc' ? `-${sortModel[0].field}` : sortModel[0].field;
};

export const mapFiltersToVariables = (filterModel) => {
  const variables = {};

  filterModel.items.forEach((filter) => {
    const { columnField, operatorValue, value } = filter;
    const backendOperator = OPERATOR_MAP[operatorValue];

    if (!backendOperator) {
      console.error(`Unknown operator: ${operatorValue}`);
      return;
    }

    const variableName = `${columnField}${backendOperator}`;
    variables[variableName] = value;
  });

  return variables;
};
