/* eslint-disable import/prefer-default-export */
import { formatMoney, addMoneyWithCurrency } from './money';

export const getAmortizationStatus = (amortizationData, type) => {
  if (amortizationData.amortizationSchedules.totalCount === 0) {
    return { title: formatMoney(0), subtitle: 'Sin plan de pago' };
  }

  const typeSchedules = amortizationData.amortizationSchedules.edges.filter(
    (edge) => edge?.node?.contentType?.model === type,
  );

  if (typeSchedules.length === 0) {
    return { title: formatMoney(0), subtitle: 'Sin plan de pago' };
  }

  const activeSchedules = typeSchedules.filter(
    (edge) => edge?.node?.status === 'ACTIVE',
  );

  const sumInstallments = (installments) => {
    if (installments.length === 0) {
      return 0;
    }
    const [first, ...rest] = installments;
    return rest.reduce(
      (sum, installment) => addMoneyWithCurrency(sum, installment.installmentManager.totalAmountToPay),
      first.installmentManager.totalAmountToPay,
    );
  };

  if (activeSchedules.length > 0) {
    const totalPaid = sumInstallments(activeSchedules.flatMap((edge) => edge.node.installments.filter(
      (installment) => installment.installmentManager.status === 'FINISHED',
    )));

    const totalAmount = sumInstallments(activeSchedules.flatMap((edge) => edge.node.installments));

    return {
      subtitle: 'Activo',
      title: `$${formatMoney(totalPaid)}/$${formatMoney(totalAmount)}`,
    };
  }

  const totalPaid = sumInstallments(typeSchedules.flatMap((edge) => edge.node.installments.filter(
    (installment) => installment.installmentManager.status === 'FINISHED',
  )));

  const totalAmount = sumInstallments(typeSchedules.flatMap((edge) => edge.node.installments));

  return {
    subtitle: 'Terminado',
    title: `$${formatMoney(totalPaid)}/$${formatMoney(totalAmount)}`,
  };
};
