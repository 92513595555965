/* eslint-disable import/prefer-default-export */
const cleanContactType = (contactType) => {
  let updatedContactType = null;
  if (contactType) {
    if (typeof contactType === 'string') {
      updatedContactType = contactType;
    }
    if (Array.isArray(contactType)) {
      updatedContactType = contactType[0]?.code || null;
    }
  }
  return updatedContactType;
};

export { cleanContactType };
