const getCountryFromTld = () => {
  // Extract the TLD from the hostname
  const tld = window.location?.hostname?.split('.')?.pop();
  switch (tld) {
    case 'cl':
      return 'Chile';
    case 'mx':
      return 'Mexico';
    default:
      return 'Chile';
  }
};

export default getCountryFromTld;
