import hasAllDocuments from './check-all-documents';

const operationalDocuments = [
  { name: 'Cédula de Identificación Fiscal.', code: 'fiscal_id_certificate', years: null, template: null },
  { name: 'Constancia de la Firma Electrónica Avanzada.', code: 'fiel_certificate', years: null, template: null },
  { name: 'Comprobante de domicilio con vigencia no mayor a tres meses.', code: 'address_proof', years: null, template: null },
  { name: 'Identificación personal del o los representantes legales', code: 'legal_identification', years: null, template: null },
  { name: 'Constancia vigente de Registro ante CONDUSEF (Solo entidades financieras)', code: 'condusef_registration', years: null, template: null },
  { name: 'Dictamen Técnico positivo emitido por la CNBV (Solo entidades financieras)', code: 'cnbv_technical_report', years: null, template: null },
];

const chileOperationalDocuments = () => false;

const mexicoOperationalDocuments = (company) => hasAllDocuments(
  company.documents || [],
  operationalDocuments,
);

const countryMethod = {
  Mexico: mexicoOperationalDocuments,
  Chile: chileOperationalDocuments,
};

const hasAllOperationalDocuments = (company) => {
  if (!company) {
    return false;
  }
  const companyDocuments = company.documents || [];
  const countryMethodFunction = countryMethod[company.masterEntity.country.name]
   || chileOperationalDocuments;

  return countryMethodFunction(companyDocuments);
};

export {
  hasAllOperationalDocuments,
  operationalDocuments,
};
