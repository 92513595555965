const parseReturn = (commission, currency) => {
  if (currency) {
    return { amount: commission * 1.19, currency };
  }
  return commission * 1.19;
};

export const calculateCommission = (commissionRules, accumulatedAmountWithIva) => {
  let commission = 0;
  const newAccumulatedAmountWithIva = accumulatedAmountWithIva.amount || accumulatedAmountWithIva;
  const currency = accumulatedAmountWithIva.currency || null;
  const fixed = commissionRules?.filter((rule) => rule.variant === 'FIXED');
  const percentage = commissionRules?.filter((rule) => rule.variant === 'PERCENTAGE');
  const mins = commissionRules?.filter((rule) => rule.variant === 'MIN')?.sort((a, b) => b.threshold - a.threshold);
  const maxes = commissionRules?.filter((rule) => rule.variant === 'MAX')?.sort((a, b) => a.threshold - b.threshold);
  if (newAccumulatedAmountWithIva <= 0) {
    return parseReturn(commission, currency);
  }
  if (fixed?.length > 0) {
    commission = fixed[0].value;
    return parseReturn(commission, currency);
  }

  if (percentage?.length > 0) {
    commission = percentage[0].value * newAccumulatedAmountWithIva;
  }

  for (let i = 0; i < mins?.length; i += 1) {
    const min = mins[i];
    if (newAccumulatedAmountWithIva >= min.threshold) {
      commission = Math.max(commission, min.value);
      break;
    }
  }

  for (let i = 0; i < maxes?.length; i += 1) {
    const max = maxes[i];
    if (newAccumulatedAmountWithIva <= max.threshold) {
      commission = Math.min(commission, max.value);
      break;
    }
  }
  return parseReturn(commission, currency);
};

export const calculateInvoiceAmounts = (invoiceData) => {
  const { days, retentionRate, monthlyRate,
    amountWithIva: { amount: amountWithIva } } = invoiceData;
  const effectiveRate = (monthlyRate / 100) * (days / 30);
  const retentionAmount = Math.round((retentionRate / 100) * amountWithIva);
  const advanceAmount = amountWithIva - retentionAmount;
  const priceDifference = Math.round(advanceAmount * effectiveRate);
  const amountMinusInterests = advanceAmount - priceDifference;
  return { effectiveRate, retentionAmount, advanceAmount, amountMinusInterests, priceDifference };
};
