/* eslint-disable import/prefer-default-export */

export const RestrictionsColorMapper = (status) => ({
  CREATED: 'default',
  PENDING: 'info',
  RESOLVED: 'success',
  FORCED: 'warning',
  DELETED: 'info',
  EXPIRED: 'warning',
}[status]);

export const CONTRACT_TYPE_TO_SPANISH = {
  NOTARY: 'Notarial',
  DIGITAL: 'Digital',
};
