/* eslint-disable import/prefer-default-export */
export const backendFiltersTransformation = (item) => {
  if (!item || !item.value) {
    return {};
  }
  const dictToReturn = {};
  let value;
  // Transform rut into companyID
  if (['company_MasterEntity_Rut', 'receiver'].includes(item.columnField) && item.value) {
    if (typeof item.value === 'string') {
      value = parseInt(item.value.replaceAll('.', '').split('-')[0], 10);
    } else {
      value = item.value.map((rut) => parseInt(rut.replaceAll('.', '').split('-')[0], 10)).filter((n) => n);
    }
  } else {
    value = item.value;
  }
  switch (item.operatorValue) {
    case 'isAnyOf':
      dictToReturn[`${item.columnField}_In`] = value;
      break;
    case '<':
    case 'before':
      dictToReturn[`${item.columnField}_Lt`] = value;
      break;
    case '>':
    case 'after':
      dictToReturn[`${item.columnField}_Gt`] = value;
      break;
    case '>=':
    case 'onOrAfter':
      dictToReturn[`${item.columnField}_Gte`] = value;
      break;
    case '<=':
    case 'onOrBefore':
      dictToReturn[`${item.columnField}_Lte`] = value;
      break;
    case 'contains':
      dictToReturn[`${item.columnField}_Icontains`] = value;
      break;
    case 'startsWith':
      dictToReturn[`${item.columnField}_Istartswith`] = value;
      break;
    case 'equals':
    case 'is':
    case '=':
      if (item.columnField === 'company_MasterEntity_Name' || item.columnField === 'receiver_Name') {
        dictToReturn[`${item.columnField}_Iexact`] = value;
      } else {
        dictToReturn[item.columnField] = value;
      }
      break;
    default:
      break;
  }
  return dictToReturn;
};
