import React from 'react';
import NoCredentialsErrorMessage from '../components/dataGrids/NoCredentialsErrorMessage';
import DataGridNoRowsOverlay from '../components/dataGrids/DataGridNoRowsOverlay';
import { useGetUser } from '../hooks';

const getErrorType = (selectedCompany) => {
  const user = useGetUser();
  if (!user.isStaff) {
    if (!selectedCompany || (!selectedCompany?.hasSiiCredentials && !selectedCompany?.isAcepta)) {
      const ErrorMessage = () => (
        <>
          No tienes registradas tus credenciales.
          Ingresa tus {' '}
        </>
      );
      return () => (
        <DataGridNoRowsOverlay Message={() => (
          <NoCredentialsErrorMessage ErrorMessage={ErrorMessage} />
        )}
        />
      );
    }
  }
  return undefined;
};

export default getErrorType;
